import { ADMINISTRATOR, ANALYST, DEVELOPER, READ_ONLY } from '#navient/constants';
import { useAuthenticator } from '@aws-amplify/ui-react';
import React, { useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import logo from '../../img/NavientLogo.png';
import { useAppDispatch, useAppSelector } from '../../redux/hook';
import { logIn, setUserAccessLevel } from '../../redux/slices/loginSlice';
import { MainViewPanel } from '../commons/MainViewPanel';
import { NavigationBar } from '../commons/NavigationBar';
import { ALMenuItem, ALTexField, BaseWrapper, BodyWrapper, Header, Logo, Wrapper } from './App.Styles';
import { accounts } from '@navient/common/dist/src/frontend-config';
import { getGlobalConfig } from '../../redux/actions/GlobalActions';
import { getRoles } from '../../redux/actions/RolesAccessAction';
import { setRoute } from '../../redux/slices/navigationSlice';
import { Navigation } from '../../AppEnums';

interface IApp {}

interface IBase {
  history: any;
}

function Base(props: IBase): React.ReactElement {
  const navRoute = useAppSelector((state) => state.navigation.route);
  const user_access = useAppSelector((state) => state.login.user.accessLevel);
  const isLogin = useAppSelector((state) => state.login.isLogin);
  const role = useAppSelector((state) => state.rolesAccess.role);

  const dispatch = useAppDispatch();
  useEffect(() => {
    props.history.push(navRoute);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navRoute]);

  useEffect(() => {
    if (!isLogin) {
      dispatch(setRoute(Navigation.DefaultRoute));
      dispatch(logIn());
    }
  });

  useEffect(() => {
    dispatch(setUserAccessLevel(role));
  }, [role]);

  const handleAccessLevelChange = (value: string) => {
    dispatch(setUserAccessLevel(value));
  };
  return (
    <BaseWrapper>
      <Header>
        <Logo src={logo} alt="navient logo" />
      </Header>
      <BodyWrapper>
        <NavigationBar history={props.history} />
        <MainViewPanel />
      </BodyWrapper>
    </BaseWrapper>
  );
}

export const App: React.FunctionComponent<IApp> = () => {
  const { user } = useAuthenticator((context) => [context.user]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    localStorage.setItem('x-cognito-jwt', user?.getSignInUserSession()?.getAccessToken().getJwtToken() || '');
    localStorage.setItem('onEditMode', '0');
    sessionStorage.setItem('project_account', accounts[0].url);
    sessionStorage.setItem('config_account', accounts[0].url);

    dispatch(getGlobalConfig());
    dispatch(getRoles());
  }, [user]);

  return (
    <Wrapper>
      <BrowserRouter>
        <Switch>
          <Route path="/" component={Base} />
        </Switch>
      </BrowserRouter>
    </Wrapper>
  );
};

App.defaultProps = {
  // bla: 'test',
};

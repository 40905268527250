import { AccessRoles } from '@navient/common';
import { GET_ROLES } from '../actions/RolesAccessAction';

export interface RolesAccessState {
  role: AccessRoles;
}

const initialState: RolesAccessState = {
  role: AccessRoles.READ_ONLY,
};

export default function reduce(state = initialState, action) {
  switch (action.type) {
    case `${GET_ROLES}_FULFILLED`:
      const data = action.payload.data;
      const role = data.find((d) => d === 'wti.administrator')
        ? AccessRoles.ADMINISTRATOR
        : data.find((d) => d === 'wti.developer')
        ? AccessRoles.DEVELOPER
        : data.find((d) => d === 'wti.analyst')
        ? AccessRoles.ANALYST
        : AccessRoles.READ_ONLY;
      state = {
        ...state,
        role: role,
      };
      break;
    case `${GET_ROLES}_REJECTED`:
      state = {
        ...state,
      };
      break;

    default:
      break;
  }

  return state;
}

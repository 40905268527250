import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { App } from './components/App';
import './index.css';
import { persistor, store } from './redux/store';
import reportWebVitals from './reportWebVitals';
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { amazonCognito } from '@navient/common/dist/src/frontend-config';

Amplify.configure({
  Auth: {
    region: amazonCognito.region,
    userPoolId: amazonCognito.userPoolId,
    userPoolWebClientId: amazonCognito.userPoolAppClientId,
  },
});


ReactDOM.render(
  <Authenticator>
    {({ signOut, user }) => (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>

          <App />
        </PersistGate>
      </Provider>
    )}
  </Authenticator>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

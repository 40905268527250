import { AccessRoles, CampaignOversightMainView } from "@navient/common";
import { IAccounts } from "@navient/common/dist/src/components/commons/types/commonTypes";
import { Component } from "react";
import { getScheduleExecutions } from "../../redux/actions/CampaignOversightActions";
import { ISchedule } from '@navient/common/dist/src/components/commons/types/schedulerTypes';
import { IDialerDefaults } from '@navient/common/dist/src/components/commons/types/globalTypes';

interface Props {
  dialerDefaults: IDialerDefaults;
  scheduleExecutions: any;
  didSucceed: boolean;
  accessLevel: AccessRoles;
  selectedAccount: IAccounts | undefined | "All";
  schedules: ISchedule[];
  setAccount: (account_name: IAccounts | undefined | "All") => void;
  getScheduleExecutions: () => void;
  getDialerDefaults: () => void;
  controlScheduleExecution: (
    action: string,
    scheduleExecutionId: string,
    pacing?: any
  ) => Promise<any>;
  getSchedules: () => void;
}
interface State { }

export class CampaignOversightView extends Component<Props, State> {
  state = {};
  handleControlScheduleExecution = (
    action: string,
    scheduleExecutionId: string,
    callback: (info: any) => void,
    pacing?: any
  ) => {
    this.props
      .controlScheduleExecution(action, scheduleExecutionId, pacing)
      .then((data) => {
        callback(data);
        getScheduleExecutions();
      })
      .catch((error) => {
        callback({ action: { type: "error" } });
      });
  };

  render() {
    return (
      <CampaignOversightMainView
        scheduleExecutions={this.props.scheduleExecutions}
        didSucceed={this.props.didSucceed}
        schedules={this.props.schedules}
        getSchedules={() => this.props.getSchedules()}
        getScheduleExecutions={() => this.props.getScheduleExecutions()}
        controlScheduleExecution={(
          action: string,
          scheduleExecutionId: string,
          callback: (info: any) => void,
          pacing?: any
        ) =>
          this.handleControlScheduleExecution(
            action,
            scheduleExecutionId,
            callback,
            pacing
          )
        }
        dialerDefaults={this.props.dialerDefaults}
        getDialerDefaults={() => this.props.getDialerDefaults()}
        accessLevel={this.props.accessLevel}
        // getCampaigns={() => this.props.getCampaigns()}
        // updateCampaignByID={(
        //   campaign: ICampaign,
        //   id: string,
        //   callback: (info: any) => void
        // ) => this.handleCampaignUpdate(campaign, id, callback)}
        selectedAccount={this.props.selectedAccount}
        setAccount={(account: IAccounts | undefined | "All") =>
          this.props.setAccount(account)
        }
      />
    );
  }
}

export default CampaignOversightView;

import styled from "react-emotion";

export const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Name = styled("div")`
  display: flex;
  font-size: 8px;
  color: #c7c7c7;
  text-align: center;
`;

import styled from "react-emotion";

export const Wrapper = styled("div")`
  display: grid;
  height: calc(100vh - 40px);
  gap: 15px;
  grid-template-rows: 1fr;
  justify-items: center;
  background: #46464e;
`;

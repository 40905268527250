import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface LoginState {
  isLogin: boolean;
  user: any;
}

const initialState: LoginState = {
  isLogin: false,
  user: {
    accessLevel: "readOnly",
  },
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    logIn: (state) => {
      state.isLogin = true;
    },
    logOut: (state) => {
      state.isLogin = false;
    },
    saveUser: (state, action: PayloadAction<any>) => {
      state.user = action.payload;
    },
    setUserAccessLevel: (state, action: PayloadAction<any>) => {
      state.user = { ...state.user, accessLevel: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const { logIn, logOut, setUserAccessLevel } = loginSlice.actions;

export default loginSlice.reducer;
